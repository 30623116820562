var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress-page" },
    [
      _c(
        "progress-block",
        {
          attrs: {
            title: "Ход строительства",
            "line-right": true,
            building: _vm.building,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "progress-end",
              attrs: { slot: "rightBlock" },
              slot: "rightBlock",
            },
            [
              _c("div", { staticClass: "progress-end__title" }, [
                _vm._v("2022"),
              ]),
              _c("div", { staticClass: "progress-end__subtitle" }, [
                _vm._v(" сдача проекта"),
                _c("br"),
                _vm._v("2 квартал "),
              ]),
            ]
          ),
        ]
      ),
      _c("best-apartments-block"),
      _c("slider-news", {
        attrs: { title: "Последние Новости", navigation: true },
      }),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }