<template>
  <div class="progress-page">
    <progress-block
      title="Ход строительства"
      :line-right="true"
      :building="building"
    >
      <div slot="rightBlock" class="progress-end">
        <div class="progress-end__title">2022</div>
        <div class="progress-end__subtitle">
          <!-- ГОД ОКОНЧАНИЯ СТРОИТЕЛЬСТВА -->
          сдача проекта<br />2 квартал
        </div>
      </div>
    </progress-block>
    <best-apartments-block />
    <slider-news title="Последние Новости" :navigation="true" />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProgressBlock from "@/components/blocks/ProgressBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import SliderNews from "@/components/blocks/SliderNews";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";

export default {
  name: "ParkingPage",
  components: {
    ProgressBlock,
    CallbackBlock,
    SliderNews,
    BestApartmentsBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Онлайн-трансляция хода строительства ЖК MainStreet.",
      },
    ],
  },
  data: () => ({}),
  computed: {
    ...mapState({
      building: (state) => state.building,
    }),
  },
};
</script>

<style lang="scss" scoped>
.progress-end {
  display: flex;
  align-items: center;
  margin: -19px 0 10px 0;

  @media screen and (min-width: $screen-md) {
    margin: 0;
  }

  &__title {
    font-size: 64px;
    line-height: 130%;
    letter-spacing: 0.03em;
    color: #18181b;
  }
  &__subtitle {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 200%;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    color: rgba(24, 24, 27, 0.7);
    width: 105px;

    margin-left: 10px;
    padding-left: 18px;
    border-left: 1px solid rgba(24, 24, 27, 0.2);

    @media screen and (min-width: $screen-xs) {
      margin-left: 18px;
      font-size: 10px;
    }
  }
}
</style>
